<template>
  <div class="q-mt-lg q-pa-md flex row justify-center">
      <div class="text-body2 myrules text-center">
        {{ $t("rule_1") }}
        <p v-if="myprofile.ulevel < 1" class="q-ma-md">
        <span v-if="total_remain >= 0" class="text-bold">
          {{$t("total_remain")}}:{{ total_remain }} 
        </span>
        <span v-if="today_remain >= 0" class="text-bold q-ml-sm"> 
          {{$t("today_remain")}}: {{ today_remain }}
        </span>
        </p>
        <q-btn to="/member" color="orange" size="medium">{{ $t("join_now") }}</q-btn>
      </div>
</div>
  <div v-if="myprofile.ulevel >= 1 || today_remain>0" class="q-pa-md flex row justify-center">
    <q-form ref="form" class="q-gutter-md nft-form">

      <div class="">
        <input
          type="file"
          ref="nft_file"
          @change="upload_nft"
          class="hidden"
          accept="image/*"
        />

        <q-img
          v-if="nft_src == null"
          class="nft-img"
          :ratio="1"
          src="@/assets/placeholder.jpg"
          @click.stop="nft_file.click()"
          :style="upload_file_style"
        >
          <div class="overlay">
            <q-icon class="myicon absolute-center" size="xl" name="edit" />
          </div>
        </q-img>
        <q-img
          v-else
          class="nft-img"
          :ratio="1"
          :src="nft_src"
          @click.stop="nft_file.click()"
        >
          <div class="overlay">
            <q-icon class="myicon absolute-center" size="xl" name="edit" />
          </div>
        </q-img>
        <p class="q-ma-sm text-body">{{ $t("upload_nft") }}</p>
      </div>

      <q-input
        filled
        v-model="nft_name"
        :label="$t('nft_title')"
        hint="Choose your NFT name"
        lazy-rules
        :rules="[(val) => (val && val.length > 0) || 'Please type something']"
      />

      <q-input
        filled
        v-model="nft_author"
        :label="$t('nft_author') + ' *'"
        hint="NFT Author"
        lazy-rules
        :rules="[(val) => (val && val.length > 0) || 'Please type something']"
      />

      <q-input
        filled
        type="textarea"
        v-model="nft_desc"
        :label="$t('nft_desc')"
        lazy-rules
        :rules="[(val) => (val && val.length > 0) || 'Please type something']"
      />

      <q-input
        filled
        v-model="nft_link"
        :label="$t('external_link')"
        hint="External Link,url start with https://"
        lazy-rules
      />

      <q-input
        filled
        v-model="nft_audio"
        :label="$t('audio')"
        hint="Set your audio url,start with https://"
      />

      <q-input
        filled
        v-model="nft_video"
        :label="$t('video')"
        hint="Set your video url, start with https://"
      />

      <q-input
        v-if="myprofile.ulevel >= 2"
        filled
        v-model.number="nft_price"
        :label="$t('nft_price')"
        hint="Set your NFT sell price"
        type="number"        
        :rules="[(val) => parseInt(val) >= 0 || 'Issue price can not be negative']"
      />
      
      <q-input
        v-else
        filled
        v-model.number="nft_price"
        type="number"
        :label="$t('nft_price')"
        hint="Set your NFT sell price"
        :rules="[(val) => parseInt(val) >= 3 || 'Issue price be greater than $3']"
      />

      <q-input
        filled
        v-model.number="nft_count"
        type="number"
        :label="$t('token_quantity')"
        hint="Set your NFT Token amount"
        :rules="[(val) => parseInt(val) >= 0 || 'Issue quantity must greater than 0']"
      />
      <!-- <q-toggle v-model="accept" label="I accept the license and terms" /> -->

      <q-list>
        <p class="text-body">{{ $t("payment_options") }}</p>
        <q-item class="">
          <q-item-section avatar>
            <q-checkbox disable v-model="is_credit_card"
              >Credit Card / Google Pay / Apple Pay</q-checkbox
            >
          </q-item-section>
          <q-item-section>
            <!-- <q-input
              label="Credit Card / Google Pay / Apple Pay"
              v-model="credit_info"
            ></q-input> -->
            <!-- <q-item-label caption
              >Bind your Stripe Account, receive payment directly and instantly.
              (Coming soon...)</q-item-label
            > -->
          </q-item-section>
        </q-item>
        <!-- <q-item class="bg-grey-2">
          <q-item-section avatar>
            <q-checkbox v-model="is_buy_directly"></q-checkbox>
          </q-item-section>
          <q-item-section>
            <q-input v-model="direct_info"></q-input>
            <q-item-label caption>{{ $t("buy_direct") }}</q-item-label>
          </q-item-section>
        </q-item> -->
      </q-list>
      <div class="text-body2 myrules">
        {{ $t("content_rules") }}
      </div>
      <div class="text-center q-ma-xl">
        <h6 class="text-secondary" v-if="myprofile.user_mint">
          Create your OWNED NFT! <br />
          Own your ERC1155 Smart Contract on Polygon!
        </h6>
        <h6 class="text-secondary" v-else>
          Create NFT for FREE! <br />CNFT pay your gas fee!
        </h6>
        <q-btn
          :loading="minting"
          size="lg"
          class="q-pl-xl q-pr-xl"
          :label="$t('mint_now')"
          color="secondary"
          @click="validate"
        >
          <template v-slot:loading>
            <q-spinner-hourglass class="on-left" />
            Loading
          </template>
        </q-btn>
      </div>
    </q-form>
  </div>

  <q-dialog v-model="mint_confirm" persistent>
    <q-card>
      <q-card-section class="">
        <div class="text-h6">{{ $t("mint_now") }}</div>
      </q-card-section>
      <q-card-section class="text-body">
        You are minting 1 NFT of {{ nft_name }} for FREE! It will take several
        minutes, please don't refresh or close page during minting. <br />

        Your NFT will be available when we approved!
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="grey" v-close-popup />
        <q-btn
          v-if="myprofile.user_mint"
          flat
          :label="$t('mint_now')"
          color="orange"
          v-close-popup
          @click="user_mint"
        />
        <q-btn
          v-else
          flat
          :label="$t('mint_now')"
          color="orange"
          v-close-popup
          @click="sys_mint"
        />
        <q-btn
          v-if="myprofile.role == 1"
          flat
          :label="$t('MINT_GASLESS')"
          color="orange"
          v-close-popup
          @click="gasless_mint"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog seamless full-width position="bottom" v-model="alert">
    <q-card>
      <q-card-section>
        <div class="text-h6">Alert</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ $t(alert_msg) }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat size="20px" label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style lang="sass" scoped>
.nft-img
  cursor: pointer
  :hover .overlay
    display: block
    background: rgba(0, 0, 0, .3)
    opacity: 1

.overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0
  background: rgba(0, 0, 0, 0)
  transition: background 0.5s ease
  transition: opacity 0.5s ease

.myrules
  max-width: 960px
</style>

<script>
import { supabase } from "../supabase";
import { ref, onBeforeMount, toRefs, watch, inject } from "vue";
import ax from "axios";
import { w3, APIURL } from "../web3";
import abi from "@/assets/cnft.abi.json";
import bytecode from "@/assets/cnft.bytecode.json";
import { supabase as s } from "../supabase";
import { useQuasar } from "quasar";

export default {
  name: "CreateMint",
  setup(prop, { emit }) {
    const uploading = ref(false);
    const nft_src = ref(null);
    const nft_file = ref(null);

    const q = useQuasar();
    const user = ref(s.auth.user());
    const myprofile = inject("profile");
    const total_remain = ref(-1);
    const today_remain = ref(-1);

    return {
      myprofile: myprofile,
      user,
      minting: ref(false),
      nft_name: ref(""),
      nft_author: ref(""),
      nft_desc: ref(""),
      nft_link: ref(""),
      nft_video: ref(""),
      nft_audio: ref(""),
      nft_price: ref("0"),
      nft_count: ref("1"),
      nft_contract: ref(""),
      nft_src,
      nft_file,
      uploading,
      q,
      is_credit_card: ref(true),
      is_buy_directly: ref(false),
      direct_info: ref(""),
      credit_info: ref(""),
      coinbase_info: ref(""),
      mint_confirm: ref(false),
      upload_file_style: ref(""),
      alert: ref(false),
      alert_msg: ref(""),
      total_remain,
      today_remain,
    };
  },
  mounted() {
    this.$nextTick(async () => {
      // Code that will run only after the
      // entire view has been rendered
      // get user free
      let aa = await s
        .from("profiles")
        .select("ulevel")
        .eq("id", this.user.id);
      let p = aa["data"][0];
      console.log("injected myprofile:", p);
      if (p.ulevel < 1) {
        // get today created
        const total = await s
          .from("nfts")
          .select("*", { count: "exact" })
          .eq("uowner", this.user.id);
        if (total.error) {
          console.log(total.error);
        } else {
          this.total_remain = 25 - total.count > 0 ? 25 - total.count : 0;
          console.log("Your total nft:", this.total_remain);
        }

        if (this.total_remain > 3) {
          // get total created
          let start_date = new Date(
            new Date().getTime() - 24 * 60 * 60 * 1000
          ).toISOString();
          const today = await s
            .from("nfts")
            .select("*", { count: "exact" })
            .eq("uowner", this.user.id)
            .gt("created_at", start_date);
          if (today.error) {
            console.log(today.error);
          } else {
            this.today_remain = 3 - today.count > 0 ? 3 - today.count : 0;
            console.log("Your today nft:", this.today_remain.value);
          }
        } else if (this.total_remain >= 0 && this.total_remain <= 3) {
          this.today_remain = this.total_remain;
        }
      } else{
          // all paid user limit daily issue count
          // get total created
          let start_date = new Date(
            new Date().getTime() - 24 * 60 * 60 * 1000
          ).toISOString();
          const today = await s
            .from("nfts")
            .select("*", { count: "exact" })
            .eq("uowner", this.user.id)
            .gt("created_at", start_date);
          if (today.error) {
            console.log(today.error);
          } else {
            this.today_remain = 3 - today.count > 0 ? 3 - today.count : 0;
            console.log("Your today nft:", this.today_remain.value);
          }
      }
    });
  },
  methods: {
    validate() {
      if (!this.nft_src) {
        this.upload_file_style = "border:2px solid red";
        return;
      }
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.mint_confirm = true;
        }
      });
    },
    async upload_nft(evt) {
      const files = evt.target.files;
      try {
        if (!files || files.length === 0) {
          throw new Error("You must select an image to upload.");
        }

        const file = files[0];
        console.log("file size:",file.size)
        if (file.size > 1024*256) {
          throw new Error("Image size too big, maximum 256KB. try https://www.jpeg.io/ reduce your image size.");
        }        
        const ext = file.name.split(".").pop();
        const fname = `${Math.random()}.${ext}`;
        const fpath = "nft/" + `${fname}`;
        let { error: uploadError } = await s.storage
          .from("cnft")
          .upload(fpath, file);
        if (uploadError) throw uploadError;
        const { publicURL, error } = await s.storage
          .from("cnft")
          .getPublicUrl(fpath);
        if (error) console.log(error);
        this.nft_src = publicURL;
      } catch (error) {
        alert(error.message);
      }
    },

    async _updatedb(contract, count) {
      console.log("Update database");
      //create nft record  owner, address, json
      console.log("Create NFT record");
      let flag = 0;
      if (this.myprofile.ulevel > 1) {
        flag = 0;
      }
      const record = {
        uissuer: "cfdaecc4-049b-4099-9227-8a6aed91b227",
        id: contract,
        uowner: this.user.id,
        owner: this.user.user_metadata.wallet,
        balance: count,
        flag: flag,
        data: this._get_nft_json(),
      };

      console.log(record);
      const { data, error } = await supabase.from("nfts").insert([record]);

      if (error) {
        console.log(error);
      } else console.log(data);
    },

    async _updatedb_user_mint(contract, count) {
      console.log("Update database");
      //create nft record  owner, address, json
      console.log("Create NFT record");
      let flag = 0;
      if (this.myprofile.ulevel > 1) {
        flag = 0;
      }
      const record = {
        uissuer: this.user.id,
        id: contract,
        uowner: this.user.id,
        owner: this.user.user_metadata.wallet,
        balance: count,
        flag: flag,
        data: this._get_nft_json(),
      };

      console.log(record);
      const { data, error } = await supabase.from("nfts").insert([record]);

      if (error) {
        console.log(error);
      } else console.log(data);
    },

    _get_nft_json() {
      console.log("Generate json file");
      const metadata = {
        issuer: "0xffb2ca78e8e72411e82d8684ac752846868d4f86",
        name: this.nft_name,
        description: this.nft_desc,
        image: this.nft_src,
        link: this.nft_link,
        author: this.nft_author,
        price: this.nft_price,
        count: this.nft_count,
        audio: this.nft_audio,
        video: this.nft_video,
        direct_info: this.direct_info,
        credit_info: this.credit_info,
        is_credit_card: this.is_credit_card,
        is_buy_directly: this.is_buy_directly,
      };
      console.log(metadata);
      return metadata;
    },
    async gasless_mint() {
      
      this.minting = true;
      this.q.loading.show({
        message:
          "Deploying your gasless contract to mainnet, your NFT will be available when we approved!",
      });

      const url = APIURL + "/i/c2";
      const params = {
        uuid: this.user.id,
        from: this.user.user_metadata.wallet,
        flag: -1234,
        meta: this._get_nft_json(),
        forwarder: "0x4fd742c7cd8f2528518460e3739f77a7f752731f"
      };

      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: params,
        url,
      };
      console.log(options);
      ax(options)
        .then(async (res) => {
          console.log(res);
          this.nft_contract = res.data.contractAddress;

          // Insert TXN record
          const record = {
            from: this.user.user_metadata.wallet,
            to: this.nft_contract,
            type: "ISSUE",
            status: "DONE",
            txn: res,
          };
          const { data, error } = await supabase.from("txns").insert([record]);

          if (error) {
            console.log(error);
          } else console.log(data);

          this.q.loading.hide();
          this.q.notify("Mint Gasless NFT Success!");
          this.q.notify("Contract:" + this.nft_contract);
          this.minting = false;
          this.$router.push("/shop/" + this.user.id);
        })
        .catch((error) => {
          console.log("Show error notification!");
          this.alert = true;
          this.alert_msg = "Polygon Chain Network is busy, please try later.";
          this.q.loading.hide();
          this.minting = false;
        });
    },
    async sys_mint() {
      this.minting = true;
      this.q.loading.show({
        message:
          "Deploying your contract to mainnet, your NFT will be available when we approved!",
      });

      const url = APIURL + "/i/create";
      const params = {
        uuid: this.user.id,
      };

      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: params,
        url,
      };
      console.log(options);
      ax(options)
        .then(async (res) => {
          console.log(res);
          this.nft_contract = res.data.contractAddress;
          await this._updatedb(this.nft_contract, this.nft_count);

          // Insert TXN record
          const record = {
            from: this.user.user_metadata.wallet,
            to: this.nft_contract,
            type: "ISSUE",
            status: "DONE",
            txn: res,
          };
          const { data, error } = await supabase.from("txns").insert([record]);

          if (error) {
            console.log(error);
          } else console.log(data);

          this.q.loading.hide();
          this.q.notify("Mint NFT Success!");
          this.q.notify("Contract:" + this.nft_contract);
          this.minting = false;
          this.$router.push("/shop/" + this.user.id);
        })
        .catch((error) => {
          console.log("Show error notification!");
          this.alert = true;
          this.alert_msg = "Polygon Chain Network is busy, please try later.";
          this.q.loading.hide();
          this.minting = false;
        });
    },
    async user_mint() {
      this.minting = true;
      this.q.loading.show({
        message: "Deploying your owned contract to mainnet...",
      });

      // const nonce = await w3.eth.getTransactionCount(
      //   this.user.user_metadata.wallet,
      //   "pending"
      // );
      var BN = w3.utils.BN;
      const cur_fee = await w3.eth.getGasPrice()
      let gas_fee_gwei = w3.utils.fromWei(cur_fee, "gwei")
      let gas_fee = (new BN(cur_fee) * new BN(2));
      const tx = {
        from: this.user.user_metadata.wallet,
        gas: 6000000,
        gasPrice: gas_fee,
        // this encodes the ABI of the method and the arguements
        // nonce: nonce,
        data: bytecode.data,
      };
      console.log("Tx Object:", tx);
      console.log("Sign transaction.");
      const sign_it = w3.eth.accounts.signTransaction(
        tx,
        this.user.user_metadata.key
      );
      console.log(sign_it);

      sign_it.then((signed_tx) => {
        console.log("Broadcast transaction.");
        const send_tx = w3.eth.sendSignedTransaction(
          signed_tx.raw || signed_tx.rawTransaction
        );
        send_tx.on("receipt", async (receipt) => {
          console.log("Deploy finished!", receipt);
          this.nft_contract = receipt.contractAddress;
          // create contract object
          await this._updatedb_user_mint(this.nft_contract, this.nft_count);

          // Insert TXN record
          const record = {
            from: this.user.user_metadata.wallet,
            to: this.nft_contract,
            type: "ISSUE",
            status: "DONE",
            txn: receipt,
          };
          const { data, error } = await supabase.from("txns").insert([record]);
          if (error) {
            console.log(error);
          } else console.log(data);

          this.q.loading.hide();
          this.q.notify("Mint NFT Success!");
          this.q.notify("Contract:" + this.nft_contract);
          this.minting = false;
          this.$router.push("/shop/" + this.user.id);
        });
        send_tx.on("error", (err) => {
          console.log("Show error notification!");
          alert("Deploy to mainnet error, please retry.");
          this.q.loading.hide();
          this.minting = false;
        });
      });
    },
  },
};
</script>
